<template>
  <v-layout
    row
    px-5
  >
    <v-flex
      xs12
    >
      <v-layout
        row
        wrap
      >
        <v-flex
          xs12
          my-4
        >
          <v-flex xs4>
            <v-card
              flat
              color="transparent"
              style="width: 210px;"
              router
              :to="{name:'faq:list'}"
            >
              <v-layout
                align-center
                justify-start
                row
              >
                <v-icon
                  small
                  class="mr-2"
                  color="#00b2c0"
                >
                  far fa-arrow-left
                </v-icon>
                <v-flex>
                  <div
                    class="body-1"
                    style="color: #00b2c0"
                  >
                    Regresar a categorías
                  </div>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-flex>
        <v-flex
          v-if="faq"
          xs12
        >
          <v-layout
            row
            wrap
          >
            <v-flex
              xs12
              md6
            >
              <v-layout
                row
                wrap
              >
                <v-flex
                  xs12
                  mb-4
                >
                  <div
                    class="headline"
                    style="font-weight: bold"
                  >
                    {{ faq.question }}
                  </div>
                </v-flex>
                <v-flex
                  v-if="faq.answer"
                  xs12
                  mb-5
                >
                  <p
                    v-for="(p, index) in faq.answer.split('\n')"
                    :key="`parr-${index}`"
                    style="text-align: justify"
                    class="body-1"
                  >
                    {{ p }}
                  </p>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-spacer />
            <v-flex
              xs12
              md5
            >
              <v-card
                class="mb-5 elevation-20"
                :mr-5="$vuetify.breakpoint.mdAndUp"
                style="border-radius: 10px"
              >
                <v-card-title
                  style="background: #00b2c0"
                  class="white--text"
                >
                  <v-layout
                    align-center
                    justify-start
                    row
                  >
                    <v-flex
                      xs12
                      md11
                    >
                      <div class="title">
                        <v-icon
                          style="margin-right: 5px"
                          color="white"
                        >
                          fal fa-lightbulb-exclamation
                        </v-icon>
                        También te puede interesar
                      </div>
                    </v-flex>
                  </v-layout>
                </v-card-title>
                <v-card-actions>
                  <v-layout column>
                    <v-card
                      v-for="(relatedQuestion, index) in faq.related_questions"
                      :key="relatedQuestion.id"
                      router
                      :to="{name: 'faq:detail', params: {faqid: relatedQuestion.id}}"
                      flat
                      @click="loadFAQ()"
                    >
                      <template v-if="index !== 0">
                        <v-divider />
                      </template>
                      <v-layout
                        align-center
                        justify-space-between
                        row
                        ma-3
                      >
                        <v-flex xs11>
                          <span class="body-1">
                            {{ relatedQuestion.question }}
                          </span>
                        </v-flex>
                        <v-icon
                          small
                        >
                          far fa-arrow-right
                        </v-icon>
                      </v-layout>
                    </v-card>
                  </v-layout>
                </v-card-actions>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import axios from 'axios';

export default {
  name: 'FAQDetail',
  data() {
    return {
      faq: {},
    };
  },

  computed: {

  },

  mounted() {
    this.$nextTick(() => {
      this.loadFAQ();
    });
  },

  methods: {
    loadFAQ() {
      axios.get(this.$store.state.endpoints.faq.detail(this.$route.params.faqid))
        .then(response => {
          this.faq = response.data;
        });
    },
  },

};

</script>
